/* eslint-disable */
import { useTranslation } from 'react-i18next';
import React, { useState, useEffect, useRef, useMemo } from 'react';
import useAreaLimit from '../../common/hooks/useAreaLimit';
import { initCookieControl } from '../../common/cookieControl/index';
import {
  termOfService,
  secondDinnerPrivacyNotice,
  nuversePrivacyNotice
} from '../../utils/footerLink';
import { customRedirect } from '../../utils/link/goPage';
import { getSpecFont } from '../../utils/fontClass/index';
import './index.less';
import { PrivacyUpdatePop } from '@fe/game-sdk';
import { langMap } from '../../common/services/siteData';

export default function FooterForPC({ customStyle }) {
  const { t, i18n } = useTranslation();
  const [needCookieSetting, setSetting] = useState(false);
  const { currentCity } = useAreaLimit();
  const cookie = useRef(null);
  const [links, setLinks] = useState({
    term: '',
    nuverse: '',
    second: ''
  });
  const [nvsLink, setNvsLink] = useState('');
  const [canShowFuntap, setCanShowFuntap] = useState(false);

  const logoName = useMemo(() => {
    if (i18n.language === 'zh-TW') {
      return 'kere';
    }
    return 'nvsgames';
  }, [t]);

  const showCookieSetting = () => {
    cookie.current.showSideBanner(false);
  };

  const goCreditsPage = () => {
    customRedirect('./credits', true);
  };

  useEffect(() => {
    if (!!currentCity) {
      initCookieControl({
        currentCity: currentCity,
        ref: cookie,
        done: () => {
          setSetting(true);
        }
      });
    }
  }, [currentCity]);

  useEffect(() => {
    let lang = i18n.language;
    let term = termOfService[lang];
    let nuverse = nuversePrivacyNotice[lang];
    let second = secondDinnerPrivacyNotice[lang];
    setLinks({
      term: term,
      nuverse: nuverse,
      second: second
    });
    setCanShowFuntap(i18n.language === 'vi');
  }, [t]);

    // 初始化协议更新弹窗
    useEffect(() => {
      new PrivacyUpdatePop({
        language: langMap[i18n?.language] || 'en-US',
        siteConfig: {
          appId: 262304,
          website: 93,
          page: 25,
          block: 71,
          channel: 71,
          region: 'va'
        },
        onInit: (popInstance) => {
          setNvsLink(popInstance.privacyUrl);
        }
      })
    }, [i18n?.language]);

  return (
    <div styleName="pc-footer" style={customStyle}>
      <div styleName="top-icon">
        <span styleName="marvel"></span>
        <span styleName={logoName}></span>
        <span styleName="seconddinner"></span>
        {/* {canShowFuntap && <span styleName="funtap"></span>} */}
        {i18n.language === 'zh-TW' && <span styleName="age12"></span>}
      </div>
      <div styleName="bottom-link" className={getSpecFont(i18n.language)}>
        <a href="mailto:support@marvelsnap.mail.helpshift.com" styleName="hover-nav">
          {t('contact_us')}
        </a>
        <a href="https://marvelsnap.helpshift.com/" target="_blank" styleName="hover-nav faq">
          {t('faq')}
        </a>
        {needCookieSetting && (
          <span styleName="hover-nav cookie" onClick={showCookieSetting}>
            {t('cookie_settings')}
          </span>
        )}
        <span styleName="hover-nav" onClick={goCreditsPage}>
          {t('credits')}
        </span>
      </div>
      <div styleName="privacy-link" className={getSpecFont(i18n.language, false)}>
        <a href={links.second} target="_blank">
          {t('second_dinner_privacy_notice')}
        </a>
        <a href={nvsLink} target="_blank">
          {t('nuverse_privacy_notice')}
        </a>
        <a href={links.term} target="_blank">
          {t('terms_of_service')}
        </a>
        <a
          href="https://sf19-draftcdn-sg.ibytedtos.com/obj/ies-hotsoon-draft-sg/Snap/ac8e2c93-4c9d-48ea-8cbc-35e1ab713f2a.html"
          target="_blank"
        >
          {t('cookie_policy').toUpperCase()}
        </a>
        <span id='opt-out-button'></span>
      </div>
      <p styleName="marvel-text">© MARVEL</p>
    </div>
  );
}
